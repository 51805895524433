:root {
  --main-color: #67ac5b;
  --light-main-color: #e4fbe1;
  --super-light-main-color: rgba(46, 125, 50, 0.08);
  --dark-main-color: #44813a;
  --black: #0b3704;
}

body {
  margin: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
